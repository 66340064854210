<template>
  <div class="container">
    <div class="image-container">
      <img :src="card.title_image" alt="" />
    </div>
    <div class="title">
      {{ card.title }}
    </div>
    <div class="sub-title">
      {{ card.sub_title }}
    </div>
  </div>
</template>

<script>
import { getSiteConfigs } from "@/api/site-config";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    card: Object,
  },

  beforeMount() {},
});
</script>

<style lang="less" scoped>
div.container {
  display: block;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  text-align: left;
  position: relative;
  color: #161823;
}

.image-container {
    position: relative;
    overflow: hidden;
    height: 120px;
    width: 240px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }
}

.sub-title {
  font-size: 12px;
}
</style>
