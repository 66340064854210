<template>
  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div
          v-html="redbookFaceToHtml(message.text)"
          style="white-space: pre-line"
        ></div>
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <img :src="message.url" referrerpolicy="no-referrer" />
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_video'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <video
          :src="message.share_url"
          controls
          width="400"
          height="300"
        ></video>
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_note'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div class="note-unit">
          <div class="cover" v-if="message.cover_url">
            <img :src="message.cover_url" style="height: 80px" alt="" />
          </div>

          <div>
            <div class="body" style="padding: 0 5px; white-space: normal">
              笔记标题：{{ message.title }}
              <a
                v-if="hostOS === 'WeChat'"
                @click="rediretUrl(message.share_url)"
                >查看笔记</a
              >
              <div style="color: gray">
                {{ message.publish_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    style="overflow: hidden"
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_note_cmt'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div>用户评论：</div>
        <span>{{ message.content }}</span>
        <div class="note-unit">
          <div class="cover" v-if="message.cover_url">
            <img :src="message.cover_url" style="height: 80px" alt="" />
          </div>

          <div class="body" style="padding: 0 5px; white-space: normal">
            笔记标题：{{ message.title }}
          </div>
        </div>
        <div class="bubble-type">
          评论
          <a @click.stop="replyCMT()">回复</a>
        </div>
      </div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_loading_page'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div class="card-unit">
          <div class="cover">
            <img referrerpolicy="no-referrer" :src="message.cover_url" alt="" />
          </div>

          <div class="body">
            <div class="title">{{ message.title }}</div>
            <div class="description">
              {{ message.page_desc || message.sub_title }}
            </div>
          </div>
        </div>
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div class="card-unit">
          <div class="cover">
            <img referrerpolicy="no-referrer" :src="message.cover_url" alt="" />
          </div>

          <div class="body">
            <div class="title">{{ message.title }}</div>
            <div class="description">
              {{ message.page_desc || message.sub_title }}
            </div>
          </div>
        </div>
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-redbook"
    v-if="message.feType === 'redbook_client_social_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div class="card-unit">
        <div class="cover">
          <img referrerpolicy="no-referrer" :src="message.image_url" alt="" />
        </div>

        <div class="body">
          <div class="title">{{ message.name }}</div>
          <div class="description">{{ message.title }}</div>
        </div>
      </div>
      </div>
      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import ExtraMessage from "@/components/messages/ExtraMessage.vue";

import { redbookFaceToHtml as redbookface } from "@/filters/redbookface";
import { cloneDeep } from "lodash";
import { getHostType } from "@/utils/host";

export default defineComponent({
  props: {
    message: Object,
  },

  components: {
    "app-extra-message": ExtraMessage,
  },

  computed: {
    redbookFaceToHtml() {
      return (v) => {
        return redbookface(v);
      };
    },
  },

  methods: {
    replyCMT() {
      this.$store.commit("UPDATE_TO_REPLY_CMT", cloneDeep(this.message));
    },

    rediretUrl(url) {
      window.open(url);
    },
  },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
      hostOS: "",
    };
  },

  beforeMount() {
    this.hostOS = getHostType();
  },
});
</script>

<style lang="less" scoped>
.note-unit {
  display: flex;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  align-items: center;

  background: white;
  color: black;
  padding: 5px 10px;

  width: 250px;
  border-radius: 5px;
}

.card-unit {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  width: 250px;
  height: 60px;
  background: white;
  color: black;

  width: 250px;
  border-radius: 5px;

  padding: 5px 10px;

  .cover img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;

    .description {
      color: #999;
    }
  }
}
</style>
