<template>
  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div v-html="wechatFaceFilter(message.text)"></div>
      </div>
      <div class="bubble-type" v-if="message.act_type === 'faq_menu'">
        点菜单
      </div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <img :src="message.url" />
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_video'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <video :src="message.url" controls width="400" height="300"></video>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_shortvideo'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <video :src="message.url" controls width="400" height="300"></video>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_link'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <span>
        {{ message.link_title }}
      </span>
      <span>{{ message.link_url }}</span>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_location'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div v-if="message.label">{{ message.label }}</div>
      <img :src="message.map_cover" alt="" />
      <div class="text-center">点击查看大地图</div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_voice'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <audio :src="message.url" controls></audio>
      <div v-if="message.recognition">
        语音识别结果:{{ message.recognition }}
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_miniprogrampage'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <p>小程序卡片</p>
      <p>{{ message.link_title }}</p>
      <img :src="message.url" class="miniapppage-cover" />
      <p>小程序页面: {{ message.link_url }}</p>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client"
    v-if="message.feType === 'wechat_client_file'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div
        nz-row
        style="background-color: white; border-radius: 5px; padding: 10px"
      >
        <div
          style="
            min-width: 150px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 10px;
          "
        >
          <div>{{ message.filename }}</div>
          <div>
            {{ message.filesizeText }}
          </div>
        </div>

        <img
          class="not-preview"
          src="/assets/images/file-icon.png"
          style="width: 40px"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import ExtraMessage from "@/components/messages/ExtraMessage.vue";
import { WechatfaceFilter as wechatface } from "@/filters/wechatface";

export default defineComponent({
  props: {
    message: Object,
  },

  components: { "app-extra-message": ExtraMessage },

  computed: {
    wechatFaceFilter() {
      return (v) => {
        return wechatface(v);
      };
    },
  },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() {},
});
</script>
