<template>
  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <span v-html="douyinfaceFilter(message.text)"></span>
      </div>

      <div class="bubble-type">私信</div>
    </div>
    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <img :src="message.url" />
      </div>
      <div class="bubble-type">私信</div>
    </div>

    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>

  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_h5'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <app-douyin-card :cardInfo="message.body"></app-douyin-card>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">【消息卡片】</div>
  </div>

  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_video'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>
        <div class="douyin-worker-video-container">
          <img
            class="douyin-video-cover"
            :src="message.cover_url || '/assets/images/dy_unknown_video.jpg'"
            alt=""
          />

          <div class="video-card">
            <p v-if="message.share_url">视频标题：{{ message.title || "" }}</p>
            <p v-if="!message.share_url">未知视频</p>
          </div>
        </div>
      </div>

      <div class="bubble-type">
        <span class="auto-type-text">私信</span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_user_video'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">

        <template v-if="message.recalled">（已撤回）</template>
        <template v-if="hostType !== 'Android'">
          <div class="douyin-worker-video-container">
            <video :src="message.url" controls width="400" height="300"></video>
          </div>
        </template>

        <template v-if="hostType === 'Android'">
          [访客发送了一条视频，<a @click="doCopy(message.url)">点击查看</a>]
        </template>
      </div>

      <div class="bubble-type">
        <span class="auto-type-text">私信</span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-client msg-douyin"
    v-if="message.feType === 'douyin_client_cmt'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
    </div>
    <div class="msg-bubble">
      <app-douyin-cmt :message="message"></app-douyin-cmt>
    </div>

    <app-extra-message
      :message="message"
      v-if="message.extractedInfo"
    ></app-extra-message>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import ExtraMessage from "@/components/messages/ExtraMessage.vue";
import DouyinCard from "@/components/messages/special/DouyinCard.vue";
import DouyinCmt from "@/components/messages/special/DouyinCmt.vue";

import { DouyinfaceFilter as douyinface } from "@/filters/douyinface";

import { getHostType } from "@/utils/host";
import { Toast } from "vant";

export default defineComponent({
  props: {
    message: Object,
  },

  components: {
    "app-extra-message": ExtraMessage,
    "app-douyin-card": DouyinCard,
    "app-douyin-cmt": DouyinCmt,
  },

  computed: {
    douyinfaceFilter() {
      return (v) => {
        return douyinface(v);
      };
    },
  },

  data() {
    return {
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
      hostType: getHostType(),
    };
  },

  beforeMount() {},

  methods: {
    doCopy(url) {
      this.$copyText(url, undefined, () => {});

      Toast.success("保存成功");
    },
  },
});
</script>
