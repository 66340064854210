export function isOnIMUpdateList() {
    const companyID = localStorage.getItem("company_id") || '';

    if (
      [
        "c9i1emf65aj6",
        "cjd8vhd3v69atm",
        "cjk2irjggeuvgv",
        "cjrl1vl6udpp9b",
        "cdbfx60eg3k",
        // "cjv1de0du9o7st",
        // "c9eifep3nlue",
        // "c9ng4u7bf4ig",
        // "ckfv6lp12isc4g",
        // "cjo48dqlhg2ghk",
      ].includes(companyID)
    )
      return true;

    //get the last character of companyID
    // const lastChar = companyID.charAt(companyID.length - 1);
    // return lastChar === "a";

    return false;
  }