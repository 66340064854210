<template>
  <div
    class="msg-wrapper msg-worker msg-douyin preline"
    v-if="message.feType === 'douyin_worker_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <span v-if="message.recalled">（已撤回）</span>
        <div v-html="douyinfaceFilter(message.text)"></div>
      </div>
      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker msg-temp msg-douyin preline"
    v-if="message.feType === 'temp_douyin_worker_text'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i
          class="iconfont sending"
          v-if="
            message.sendingStatus !== 'sent' &&
            message.sendingStatus !== 'error'
          "
          >&#xe609;</i
        >
        <i class="iconfont error" v-if="message.sendingStatus === 'error'"
          >&#xe606;</i
        >
      </div>
      <div v-html="douyinfaceFilter(message.text)"></div>
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'douyin_worker_image'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <img :src="message.url" />
      </div>
      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'douyin_worker_cmt'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <app-douyin-worker-cmt :message="message"></app-douyin-worker-cmt>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'douyin_worker_video'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>

        <div class="douyin-worker-video-container">
          <div class="video-card">
            <p v-if="message.share_url">视频标题：{{ message.title || "" }}</p>
            <p v-if="!message.share_url">未知视频</p>
          </div>
        </div>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText || "视频" }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'douyin_worker_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble" style="background-color: white">
      <div class="inner-bubble">
        <div style="color: black" v-if="message.recalled">（已撤回）</div>

        <app-douyin-card :cardInfo="message"></app-douyin-card>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText || "卡片消息" }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'douyin_worker_collect_phone'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble" >
      <div class="inner-bubble">
        <div style="color: black" v-if="message.recalled">（已撤回）</div>

        <div class="collect-phone">
          <div v-html="message.text || localMessage.content"></div>
        </div>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>

  <div
    class="msg-wrapper msg-worker"
    v-if="message.feType === 'douyin_worker_premium_card'"
  >
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker"
        >({{ message.worker.name }} {{ message.worker.nickname }})回复</span
      >
    </div>
    <div class="msg-bubble" >
      <div class="inner-bubble">
        <div style="color: black" v-if="message.recalled">（已撤回）</div>

        <app-douyin-premium-card :card="message"></app-douyin-premium-card>
      </div>

      <div
        v-if="message.auto_typeText || message.is_recallable"
        class="bubble-type bubble-type-long"
      >
        {{ message.auto_typeText || "高级预约" }}
        <span
          class="recall-text"
          v-if="message.is_recallable && showRecallBtn && !message.recalled"
          @click="recall"
        >
          撤回
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import DouyinCard from "@/components/messages/special/DouyinCard.vue";
import DouyinPremiumCard from "@/components/messages/special/DouyinPremiumCard.vue";
import DouyinWorkerCmt from "@/components/messages/special/DouyinWorkerCmt.vue";

import { DouyinfaceFilter as douyinface } from "@/filters/douyinface";
import { isInRecallTimeMargin } from "@/utils/message-process/removeRecallMsg";

import { Toast, Dialog } from "vant";
import { recallMsg, getCardText } from "@/api/common";
export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    douyinfaceFilter() {
      return (v) => {
        return douyinface(v);
      };
    },
  },

  components: {
    "app-douyin-card": DouyinCard,
    "app-douyin-premium-card": DouyinPremiumCard,
    "app-douyin-worker-cmt": DouyinWorkerCmt,
  },

  data() {
    return {
      showRecallBtn: false,
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
      localMessage: this.message
    };
  },

  beforeMount() {
    this.showRecallBtn =
      this.message.is_recallable &&
      isInRecallTimeMargin(this.message.microtime || 0) &&
      this.message.auto_typeText !== "关注追粉";

      if (
      this.message.feType === "douyin_worker_collect_phone" &&
      !this.message.text
    ) {
      this.localMessage.content =
        this.douyinMobileCardService.defaultText +
        `<br><a>一键发送抖音绑定手机号</a>`;

      if (this.message.appid) {
        getCardText({
          appid: this.message.appid,
        }).subscribe(
          (res) => {
            this.localMessage.content = res.data.text;
          }
        );
      } else {
        this.localMessage.content = `（实际内容以发送时为准）<br><a>一键发送抖音绑定手机号</a>`;
      }
    }
  },

  methods: {
    recall() {
      Dialog.confirm({
        message: "确定撤回消息吗？",
      }).then(
        () => {
          this.doRecall();
        },
        () => {}
      );
    },

    doRecall() {
      recallMsg({
        uid: this.message.uid,
        microtime: this.message.microtime,
      }).subscribe(
        () => {
          Toast.success("撤回成功");
          this.$emit("onRecall", {
            microtime: this.message.microtime,
          });
        },
        (err) => {
          Toast.fail(err.response.data.message);
        }
      );
    },
  },
});
</script>

<style scoped>
.collect-phone {
  width: 250px;
  margin: 0 auto;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 5px;

  color: #4f5a62;

  ::v-deep a {
    color: #2593f4!important;
  }
}
</style>
